const allRoles = [
  "SuperAdmin",
  "MML_Urednik",
  "MML_Sifarnici",
  "MML_Admin",
  "SP_Urednik",
  "SP_Sifarnici",
  "SP_Admin",
  "Admin_Prodaja",
  "Prodaja",
  "Admin_Interni",
  "Interni_Korisnik",
  "SLGL_REG_Sifarnici",
  "SLGL_Admin",
  "SLGL_Urednik",
  "SLGL_Arhiva",
  "SLGL_MO_Admin",
  "SLGL_MO_Servis",
  "SLGL_MO_Pretraga",
  "REG_Admin",
  "REG_Urednik",
  "FP_Admin",
  "Newsletter_Admin",
  "PPP_Admin",
  "PPP_Urednik",
  "PPP_Sifarnici",
  "PEng_Urednik",
  "PEng_Prodaja",
  "Regulations_Admin",
  "Regulations_Urednik",
  "Regulations_Sifarnici"
];

export const getRolesForLocation = (location) => {
  var listOfRoles = ["SuperAdmin"];
  if (location.includes("mml"))
    return mmlSwitch(location, listOfRoles);

  else if (location.includes("sp"))
    return spSwitch(location, listOfRoles);

  else if (location.includes("loza"))
    return lozaSwitch(location, listOfRoles);

  else if (location.includes("adminPanel"))
    return adminPanelSwitch(location, listOfRoles);

  else if (location.includes("slgl"))
    return slglSwitch(location, listOfRoles);

  else if (location.includes("registries"))
    return regsSwitch(location, listOfRoles);

  else if (location.includes("frontpage"))
    return frontpageSwitch(location, listOfRoles);

  else if (location.includes("newsletter"))
    return newsletterSwitch(location, listOfRoles);

  else if (location.includes("mo"))
    return moSwitch(location, listOfRoles);

  else if (location.includes("ppp"))
    return pppSwitch(location, listOfRoles);

  else if (location.includes("regulations"))
    return regulationsSwitch(location, listOfRoles);

  else if (location.includes("peng"))
    return pengSwitch(location, listOfRoles);

  else return allRoles;
};

const mmlSwitch = (location, listOfRoles) => {
  listOfRoles.push("MML_Admin");
  switch (location) {
    case "/mml": {
      listOfRoles.push("MML_Urednik");
      return listOfRoles;
    }
    case "/mml/document": {
      listOfRoles.push("MML_Urednik");
      return listOfRoles;
    }
    case "/mml/codebooks/codebookTypes": {
      listOfRoles.push("MML_Sifarnici");
      return listOfRoles;
    }
    case "/mml/codebooks/authorities": {
      listOfRoles.push("MML_Sifarnici");
      return listOfRoles;
    }
    case "/mml/codebooks/sourceKind": {
      listOfRoles.push("MML_Sifarnici");
      return listOfRoles;
    }
    default:
      return listOfRoles;
  }
};

const spSwitch = (location, listOfRoles) => {
  listOfRoles.push("SP_Admin");
  switch (location) {
    case "/sp": {
      listOfRoles.push("SP_Urednik");
      return listOfRoles;
    }
    case "/sp/case": {
      listOfRoles.push("SP_Urednik");
      return listOfRoles;
    }
    case "/sp/codebooks/courtKinds": {
      listOfRoles.push("SP_Sifarnici");
      return listOfRoles;
    }
    case "/sp/codebooks/courts": {
      listOfRoles.push("SP_Sifarnici");
      return listOfRoles;
    }
    case "/sp/codebooks/courtDepartments": {
      listOfRoles.push("SP_Sifarnici");
      return listOfRoles;
    }
    case "/sp/codebooks/legalFields": {
      listOfRoles.push("SP_Sifarnici");
      return listOfRoles;
    }
    case "/sp/codebooks/legalMatter": {
      listOfRoles.push("SP_Sifarnici");
      return listOfRoles;
    }
    case "/sp/codebooks/legalSubMatter": {
      listOfRoles.push("SP_Sifarnici");
      return listOfRoles;
    }
    case "/sp/codebooks/legislativeActType": {
      listOfRoles.push("SP_Sifarnici");
      return listOfRoles;
    }
    case "/sp/codebooks/procedureKind": {
      listOfRoles.push("SP_Sifarnici");
      return listOfRoles;
    }
    case "/sp/codebooks/procedureOutcome": {
      listOfRoles.push("SP_Sifarnici");
      return listOfRoles;
    }
    case "/sp/codebooks/typesOfPosts": {
      listOfRoles.push("SP_Sifarnici");
      return listOfRoles;
    }
    case "/sp/codebooks/products": {
      listOfRoles.push("SP_Sifarnici");
      return listOfRoles;
    }
    case "/sp/codebooks/register": {
      listOfRoles.push("SP_Sifarnici");
      return listOfRoles;
    }
    case "/sp/advancedSearchQuery": {
      listOfRoles.push("SP_Sifarnici");
      return listOfRoles;
    }
    default:
      return listOfRoles;
  }
};

const lozaSwitch = (location, listOfRoles) => {
  listOfRoles.push("Admin_Prodaja", "Prodaja");
  switch (location) {
    case "/loza": {
      listOfRoles.push("Interni_Korisnik", "Admin_Interni");
      return listOfRoles;
    }
    case "/loza/CreateUser": {
      listOfRoles.push("Interni_Korisnik");
      return listOfRoles;
    }
    case "/loza/EditUserAccounts": {
      listOfRoles.push("Interni_Korisnik");
      return listOfRoles;
    }
    case "/loza/EditUserAccountById": {
      listOfRoles.push("Interni_Korisnik");
      return listOfRoles;
    }
    case "/loza/CreateGroupAccounts": {
      listOfRoles.push("Interni_Korisnik");
      return listOfRoles;
    }
    case "/loza/EditGroupAccounts": {
      listOfRoles.push("Interni_Korisnik");
      return listOfRoles;
    }
    case "/loza/AllRequests": {
      listOfRoles.push("Interni_Korisnik");
      return listOfRoles;
    }
    case "/loza/InternalUsers": {
      listOfRoles.push("Interni_Korisnik", "Admin_Interni");
      return listOfRoles;
    }
    case "/loza/Subscribers": {
      listOfRoles.push("Interni_Korisnik");
      return listOfRoles;
    }
    default:
      return listOfRoles;
  }
};

const adminPanelSwitch = (location, listOfRoles) => {
  listOfRoles.push("SLGL_REG_Sifarnici");
  switch (location) {
    default:
      return listOfRoles;
  }
};

const slglSwitch = (location, listOfRoles) => {
  listOfRoles.push("SLGL_Admin");
  switch (location) {
    case "/slgl": {
      listOfRoles.push("SLGL_Urednik");
      return listOfRoles;
    }
    case "/slgl/princess/index": {
      listOfRoles.push("SLGL_Arhiva", "SLGL_Urednik");
      return listOfRoles;
    }
    case "/slgl/publications/LawAdActs": {
      listOfRoles.push("SLGL_Urednik");
      return listOfRoles;
    }
    case "/slgl/publications/EditHTML": {
      listOfRoles.push("SLGL_Urednik");
      return listOfRoles;
    }
    case "/slgl/publications/ArchivedPublications": {
      listOfRoles.push("SLGL_Arhiva");
      return listOfRoles;
    }
    default:
      return listOfRoles;
  }
};

const regsSwitch = (location, listOfRoles) => {
  switch (location) {
    case "/registries": {
      listOfRoles.push("REG_Admin", "REG_Urednik");
      return listOfRoles;
    }
    case "/registries/warnings": {
      listOfRoles.push("REG_Admin", "REG_Urednik");
      return listOfRoles;
    }
    case "/registries/update": {
      listOfRoles.push("REG_Admin", "REG_Urednik");
      return listOfRoles;
    }
    case "/registries/settings": {
      listOfRoles.push("REG_Admin");
      return listOfRoles;
    }
    case "/registries/indexing": {
      listOfRoles.push("REG_Admin");
      return listOfRoles;
    }
    case "/registries/lockedActs": {
      listOfRoles.push("REG_Admin", 'REG_Urednik');
      return listOfRoles;
    }
    default:
      return listOfRoles;
  }
};

const frontpageSwitch = (location, listOfRoles) => {
  listOfRoles.push("FP_Admin");
  switch (location) {
    default:
      return listOfRoles;
  }
};

const newsletterSwitch = (location, listOfRoles) => {
  listOfRoles.push('Newsletter_Admin');
  switch (location) {
    default:
      return listOfRoles;
  }
};

const moSwitch = (location, listOfRoles) => {
  listOfRoles.push("SLGL_MO_Admin");
  switch (location) {
    case "/mo": {
      listOfRoles.push("SLGL_MO_Pretraga");
      return listOfRoles;
    }
    case "/mo/smallAdds/preview": {
      listOfRoles.push("SLGL_MO_Pretraga");
      return listOfRoles;
    }
    case "/mo/smallAdds/statistics": {
      listOfRoles.push("SLGL_MO_Pretraga");
      return listOfRoles;
    }
    case "/mo/smallAdds/statistics/filterByYear": {
      listOfRoles.push("SLGL_MO_Pretraga");
      return listOfRoles;
    }
    case "/mo/smallAdds/statistics/filterByYearAndNum": {
      listOfRoles.push("SLGL_MO_Pretraga");
      return listOfRoles;
    }
    case "/mo/service/indexSmallAdds": {
      listOfRoles.push("SLGL_MO_Servis");
      return listOfRoles;
    }
    case "/mo/service/indexSmallAddsFuruna": {
      listOfRoles.push("SLGL_MO_Servis");
      return listOfRoles;
    }

    case "/mo/service/indexNewSmallAddsFuruna": {
      listOfRoles.push("SLGL_MO_Servis");
      return listOfRoles;
    }


    default:
      return listOfRoles;
  }
};

const pppSwitch = (location, listOfRoles) => {
  listOfRoles.push("PPP_Admin");
  switch (location) {
    case "/ppp": {
      listOfRoles.push("PPP_Urednik");
      return listOfRoles;
    }
    case "/ppp/settings": {
      listOfRoles.push("PPP_Admin");
      return listOfRoles;
    }
    case "/ppp/codebooks/documentType": {
      listOfRoles.push("PPP_Sifarnici");
      return listOfRoles;
    }
    case "/ppp/codebooks/authority": {
      listOfRoles.push("PPP_Sifarnici");
      return listOfRoles;
    }

    default:
      return listOfRoles;
  }
};

const regulationsSwitch = (location, listOfRoles) => {
  listOfRoles.push("Regulations_Admin");
  switch (location) {
    case "/regulations": {
      listOfRoles.push("Regulations_Urednik");
      return listOfRoles;
    }
    case "/regulations/warnings": {
      listOfRoles.push("Regulations_Urednik");
      return listOfRoles;
    }
    case "/regulations/update": {
      listOfRoles.push("Regulations_Urednik");
      return listOfRoles;
    }
    case "/regulations/codebooks/actType": {
      listOfRoles.push("Regulations_Sifarnici");
      return listOfRoles;
    }
    case "/regulations/codebooks/authority": {
      listOfRoles.push("Regulations_Sifarnici");
      return listOfRoles;
    }
    case "/regulations/settings": {
      listOfRoles.push("Regulations_Sifarnici");
      return listOfRoles;
    }
    case "/regulations/indexing": {
      listOfRoles.push("Regulations_Sifarnici");
      return listOfRoles;
    }
    case "/regulations/lockedAreasAndActs": {
      listOfRoles.push("Regulations_Admin", 'Regulations_Urednik');
      return listOfRoles;
    }
    default:
      return listOfRoles;
  }
}

const pengSwitch = (location, listOfRoles) => {
  switch (location) {
    case "/peng": {
      listOfRoles.push("PEng_Urednik");
      return listOfRoles;
    }
    case "/peng/editText": {
      listOfRoles.push("PEng_Urednik");
      return listOfRoles;
    } 
    case "/peng/order": {
      listOfRoles.push("PEng_Prodaja");
      return listOfRoles;
    }
    case "/peng/users": {
      listOfRoles.push("PEng_Prodaja");
      return listOfRoles;
    }
    case "/peng/users/editUser": {
      listOfRoles.push("PEng_Prodaja");
      return listOfRoles;
    }
    case "/peng/users/subscriptions": {
      listOfRoles.push("PEng_Prodaja");
      return listOfRoles;
    }
    default:
      return listOfRoles;
  }
};