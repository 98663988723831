import UserService from "../../utils/authentification/keycloak";

export const navigateToPage = (path, navigate) => {
  if (path === "/mml") {
    const mainPageForApp = mmlPathBasedOnRole();
    setTimeout(() => { navigate(mainPageForApp) }, 10);
  }
  if (path === "/sp") {
    const mainPageForApp = spPathBasedOnRole();
    setTimeout(() => { navigate(mainPageForApp) }, 10);
  }
  if (path === "/loza") {
    const mainPageForApp = lozaPathBasedOnRole();
    setTimeout(() => { navigate(mainPageForApp) }, 10);
  }
  if (path === "/adminPanel") {
    const mainPageForApp = adminPanelPathBasedOnRole();
    setTimeout(() => { navigate(mainPageForApp) }, 10);
  }
  if (path === "/textEditor") {
    const mainPageForApp = textEditorPathBasedOnRole();
    setTimeout(() => { navigate(mainPageForApp) }, 10);
  }
  if (path === "/slgl") {
    const mainPageForApp = slglPathBasedOnRole();
    setTimeout(() => { navigate(mainPageForApp) }, 10);
  }
  if (path === "/registries") {
    const mainPageForApp = regPathBasedOnRole();
    setTimeout(() => { navigate(mainPageForApp) }, 10);
  }
  if (path === "/frontpage") {
    const mainPageForApp = fpPathBasedOnRole();
    setTimeout(() => { navigate(mainPageForApp) }, 10);
  }
  if (path === "/newsletter") {
    const mainPageForApp = newsletterPathBasedOnRole();
    setTimeout(() => { navigate(mainPageForApp) }, 10);
  }
  if (path === "/mo") {
    const mainPageForApp = moPathBasedOnRole();
    setTimeout(() => { navigate(mainPageForApp) }, 10);
  }
  if (path === "/ppp") {
    const mainPageForApp = pppPathBasedOnRole();
    setTimeout(() => { navigate(mainPageForApp) }, 10);
  }
  if (path === "/regulations") {
    const mainPageForApp = rsPathBasedOnRole();
    setTimeout(() => { navigate(mainPageForApp) }, 10);
  }
  if (path === "/peng") {
    const mainPageForApp = pengPathBasedOnRole();
    setTimeout(() => { navigate(mainPageForApp) }, 10);
  }
};

const mmlPathBasedOnRole = () => {
  if (UserService.isLoggedIn()) {
    if (UserService.hasRole(["MML_Urednik"])) return "/mml";
    else if (UserService.hasRole(["MML_Sifarnici"]))
      return "/mml/codebooks/codebookTypes";
    else return "/mml";
  }
};

const spPathBasedOnRole = () => {
  if (UserService.isLoggedIn()) {
    if (UserService.hasRole(["SP_Urednik"])) return "/sp";
    else if (UserService.hasRole(["SP_Sifarnici"]))
      return "/sp/codebooks/courtKinds";
    else return "/sp";
  }
};

const lozaPathBasedOnRole = () => {
  if (UserService.isLoggedIn()) {
    if (UserService.hasRole(["Admin_Interni"])) return "/loza/InternalUsers";
    else return "/loza";
  }
};

const adminPanelPathBasedOnRole = () => {
  if (UserService.isLoggedIn()) {
    return "/adminPanel";
  }
};

const textEditorPathBasedOnRole = () => {
  if (UserService.isLoggedIn()) {
    return "/textEditor";
  }
};

const slglPathBasedOnRole = () => {
  if (UserService.isLoggedIn()) {
    if (UserService.hasRole(["SLGL_Arhiva"])) return "/slgl/publications/ArchivedPublications";
    else return "/slgl";
  }
};

const regPathBasedOnRole = () => {
  if (UserService.isLoggedIn()) {
    return "/registries";
  }
};

const fpPathBasedOnRole = () => {
  if (UserService.isLoggedIn()) {
    return "/frontpage";
  }
};

const newsletterPathBasedOnRole = () => {
  if (UserService.isLoggedIn()) {
    return "/newsletter";
  }
}

const moPathBasedOnRole = () => {
  if (UserService.isLoggedIn()) {
    if (UserService.hasRole(["SLGL_MO_Servis"])) return "/mo/service/indexSmallAdds"
    else return "/mo";
  }
}

const pppPathBasedOnRole = () => {
  if (UserService.isLoggedIn()) {
    if (UserService.hasRole(["PPP_Urednik"])) return "/ppp";
    else if (UserService.hasRole(["PPP_Sifarnici"]))
      return "/ppp/codebooks/documentType";
    else return "/ppp";
  }
}

const rsPathBasedOnRole = () => {
  if (UserService.isLoggedIn()) {
    if (UserService.hasRole(["Regulations_Urednik"])) return "/regulations";
    else if (UserService.hasRole(["Regulations_Sifarnici"]))
      return "/regulations/codebooks/actType";
    else return "/regulations";
  }
}

const pengPathBasedOnRole = () => {
  if (UserService.isLoggedIn()) {
    if (UserService.hasRole(["Peng_Urednik"])) return "/peng";
    else if (UserService.hasRole(["PEng_Prodaja"]))
      return "/peng/users";
    else return "/peng";
  }
}