import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Select from "../../../common/custom-select-list/CustomSelectList.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import UserService from "../../../utils/authentification/keycloak";
import LegalMatterClient from "../../api-services/coders/legal-matter/legal-matter-client";
import LegalSubmatterClient from "../../api-services/coders/legal-sub-matter/legal-sub-matter-client";
import LegalAreaClient from "../../api-services/coders/legal-area/legal-area.client";
import ActKindClient from "../../api-services/coders/act-kind/act-kind-client";
import RegisterClient from "../../api-services/coders/register/register-client";
import CourtPracticeClient from "../../api-services/cases/cases-client";
import ProcedureOutcomeClient from "../../api-services/coders/procedure-outcome/procedure-client";
import ProcedureKindClient from "../../api-services/coders/procedure-kind/procedure-kind-client";
import HandlingResponse from "../../../common/handling-response/handlingResponse";
import {
  DeleteButton,
  DownloadButton,
  NewFileBtn,
} from "../../../common/multiple-files/multipleFiles.style";
import MultipleFiles from "../../../common/multiple-files/multipleFiles.component";
import { serializeObjectIntoFormData } from "../../../utils/serialize-form-data/serializeFormData";
import DatePicker from "react-datepicker";
import CustomDatePicker, {
  formatDateForUpdate,
} from "../../../common/custom-date-picker/CustomDatePicker.component";
import {
  handleSave,
  validateForm,
  serverValidation,
} from "../../../common/validation/validation";
import { useTranslation } from "react-i18next";
import Joi from "joi-browser";
import GlobalActReferences from "../../components/global-act-references/globalActReferences.component";
import RelatedActs from "../../components/related-acts/RelatedActs.component";
import SubmitFile from "../../../common/submit-file/SubmitFile";
import ButtonWithComfirmation from "../../../common/button-with-confirmation/ButtonWithConfirmation.component";
import CourtCase from "../../components/court-case/CourtCase.component";
import {
  CourtPracticeModel,
  courtePracticeSchema,
} from "../../models/CourtPractice";
import CourtPracticePublications from "../../components/court-practice-publications/CourtPracticePublications.component";
import {
  validateParentheses,
  validateQuotationMarks,
} from "../../../utils/common-functions/CommonFunctions";

const CreateAndUpdateCase = () => {
  const navigate = useNavigate();

  const [caseId, setCaseId] = useSearchParams();
  var caseIdValue = caseId.get("caseId");

  //podaci pribavljeni na Index stranici se cuvaju u state
  const { state } = useLocation();

  const { t } = useTranslation();

  const [courtPracticeData, setCourtPracticeData] = useState(
    new CourtPracticeModel()
  );

  //const [internalLabelName, setInternalLabelName] = useState("");
  //const [internalLabelNameTmp, setInternalLabelNameTmp] = useState("");

  const [fkProcedureKindList, setFkProcedureKindList] = useState([]);
  const [fkProcedureOutcomeList, setFkProcedureOutcomeList] = useState([]);

  const [primaryActs, setPrimaryActs] = useState([]);

  const [disputedActs, setDisputedActs] = useState([]);
  const [disputedActsName, setDisputedActsName] = useState("");

  const [relevantLaws, setRelevantLaws] = useState([]);
  const [relevantLawsName, setRelevantLawsName] = useState("");

  const [globalActReferences, setGlobalActReferences] = useState([]);

  const [
    fkClarificationCourtPracticeSubgroupList,
    setFkClarificationCourtPracticeSubgroupList,
  ] = useState([]);
  const [
    fkSentenceCourtPracticeSubgroupList,
    setFkSentenceCourtPracticeSubgroupList,
  ] = useState([]);

  const [
    fkLegalStanceCourtPracticeSubgroupList,
    setFkLegalStanceCourtPracticeSubgroupList,
  ] = useState([]);

  const [legalMatterList, setLegalMatterList] = useState([]);
  const [legalSubmatterList, setLegalSubmatterList] = useState([]);
  const [legalAreaList, setLegalAreaList] = useState([]);
  const [actKindList, setActKindList] = useState([]);
  const [registerList, setRegisterList] = useState([]);

  const [courtPracticePublications, setCourtPracticePublications] = useState(
    []
  );
  const [courtPracticeCourts, setCourtPracticeCourts] = useState([]);

  const [internalLabelFile, setInternalLabelFile] = useState(null);
  const internalLabelFileInputRef = useRef(null);

  const [photoFiles, setPhotoFiles] = useState([]); //koristi se za komponentu SubmitFile
  const [attachmentFilesTmp, setAttachmentFilesTmp] = useState([]); //koristi se za komponentu SubmitFile

  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [anonymizedFiles, setAnonymizedFiles] = useState([]);

  const [errors, setErrors] = useState({});
  const [pdfError, setPdfError] = useState("");

  const [user, setUser] = useState({ userGuid: null, lockUserFullName: "" });

  const [dataForSubmit, setDataForSubmit] = useState(null);

  const [primaryPublicationExist, setPrimaryPublicationExist] = useState(false);

  const [useTextEditorLite, setUseTextEditorLite] = useState(false);

  const client = new CourtPracticeClient();
  const legalMatterClient = new LegalMatterClient();
  const legalSubmatterClient = new LegalSubmatterClient();
  const legalAreaClient = new LegalAreaClient();
  const actKindClient = new ActKindClient();
  const registerClient = new RegisterClient();

  const outcomeClient = new ProcedureOutcomeClient();
  const procedureKindClient = new ProcedureKindClient();

  useEffect(() => {
    // if (caseIdValue) {
    //   navigate("/sp/case?caseId=" + caseIdValue, { replace: true });
    //   GetDataForUpdate();
    // }
    GetDataForUpdate();
    CourtPracticeSubgroupClarification();
    CourtPracticeSubgroupLegalStance();
    CourtPracticeSubgroupSentence();
    ProcedureOutcome();
    ProcedureKind();
  }, []);

  useEffect(() => {
    setDataForSubmit({
      ...courtPracticeData,
      relevantLawsName: relevantLawsName, //mora da se doda ovde zbog validacije
      primaryActs: [...primaryActs],
      disputedActs: [...disputedActs],
      relevantLaws: [...relevantLaws],
      courtPracticeCourts: [...courtPracticeCourts],
      courtPracticePublications: [...courtPracticePublications],
      globalActReferences: [...globalActReferences],
    });
  }, [
    courtPracticeData,
    primaryActs,
    disputedActs,
    relevantLaws,
    courtPracticeCourts,
    courtPracticePublications,
    globalActReferences,
  ]);

  // useEffect(() => {
  //   GetDataForUpdate();
  // }, [])

  useEffect(() => {
    GetDataForUpdate();
    setErrors({});
  }, [caseIdValue]);

  useEffect(() => {
    LegalMatter();
    if (courtPracticeData.fkLegalMatter) {
      LegalSubmatter();
    }
    LegalArea();
    ActKind();
    Register();
  }, [courtPracticeData.fkLegalMatter]);

  useEffect(() => {
    var actNames = disputedActsName.split("\n");
    var data = actNames.map((el) => {
      return { regActName: el };
    });
    setDisputedActs(data);
  }, [disputedActsName]);

  useEffect(() => {
    var actNames = relevantLawsName.split("\n");
    var data = actNames.map((el) => {
      return { regActName: el };
    });
    setRelevantLaws(data);
  }, [relevantLawsName]);

  useEffect(() => {
    const obj = { name: "internalLabelFile", value: internalLabelFile };
    const result = handleSave(
      obj,
      internalLabelFile,
      errors,
      courtePracticeSchema,
      t
    );
    setErrors(result.errors);
  }, [internalLabelFile]);

  const GetDataForUpdate = async () => {
    var response, res;
    if (caseIdValue) {
      if (!state) {
        response = await client.GetCourtPracticeById(caseIdValue);
        res = HandlingResponse(response);
      }
      if (res != "Error") {
        setCourtPracticeData({
          id: state ? state.data.id : res.id,
          fkLegalMatter: state ? state.data.fkLegalMatter : res.fkLegalMatter,
          fkLegalSubmatter: state
            ? state.data.fkLegalSubmatter
            : res.fkLegalSubmatter,
          fkLegalArea: state ? state.data.fkLegalArea : res.fkLegalArea,
          fkActKind: state ? state.data.fkActKind : res.fkActKind,
          hasProcedureKindDuration: state
            ? state.data.hasProcedureKindDuration
            : res.hasProcedureKindDuration,
          fkProcedureKind: state
            ? state.data.fkProcedureKind
            : res.fkProcedureKind,
          fkProcedureOutcome: state
            ? state.data.fkProcedureOutcome
            : res.fkProcedureOutcome,
          actLabelAndNumber: state
            ? state.data.actLabelAndNumber
            : res.actLabelAndNumber,
          fkRegister: state ? state.data.fkRegister : res.fkRegister,
          actEnactmentDate: state
            ? formatDateForUpdate(state.data.actEnactmentDate)
            : formatDateForUpdate(res.actEnactmentDate),
          actEnactmentYear: state
            ? state.data.actEnactmentYear
            : res.actEnactmentYear,
          actCloserDesignation: state
            ? state.data.actCloserDesignation
            : res.actCloserDesignation,
          isClarification: state
            ? state.data.isClarification
            : res.isClarification,
          isDocumentConstruction: state
            ? state.data.isDocumentConstruction
            : res.isDocumentConstruction,
          fkClarificationCourtPracticeSubgroup: state
            ? state.data.fkClarificationCourtPracticeSubgroup
            : res.fkClarificationCourtPracticeSubgroup,
          isSentence: state ? state.data.isSentence : res.isSentence,
          fkSentenceCourtPracticeSubgroup: state
            ? state.data.fkSentenceCourtPracticeSubgroup
            : res.fkSentenceCourtPracticeSubgroup,
          isLegalStance: state ? state.data.isLegalStance : res.isLegalStance,
          fkLegalStanceCourtPracticeSubgroup: state
            ? state.data.fkLegalStanceCourtPracticeSubgroup
            : res.fkLegalStanceCourtPracticeSubgroup,
          legalUnderstandingAdoptionDate: state
            ? formatDateForUpdate(state.data.legalUnderstandingAdoptionDate)
            : formatDateForUpdate(res.legalUnderstandingAdoptionDate),
          showAsNewest: state ? state.data.showAsNewest : res.showAsNewest,
          isApproved: state ? state.data.isApproved : res.isApproved,
          archived: state ? state.data.archived : res.archived,
          editorialNote: state ? state.data.editorialNote : res.editorialNote,
          locked: state ? state.data.locked : res.locked,
        });
        // setInternalLabelName(
        //   state ? state.data.internalLabelName : res.internalLabelName
        // );

        // setInternalLabelNameTmp(
        //   state ? state.data.internalLabelNameTmp : res.internalLabelNameTmp
        // );
        setPrimaryActs(state ? state.data.primaryActs : res.primaryActs);
        setDisputedActs(state ? state.data.disputedActs : res.disputedActs);
        setRelevantLaws(state ? state.data.relevantLaws : res.relevantLaws);
        setCourtPracticeCourts(
          state ? state.data.courtPracticeCourts : res.courtPracticeCourts
        );
        setCourtPracticePublications(
          state
            ? state.data.courtPracticePublications
            : res.courtPracticePublications
        );

        setAttachmentFiles(state ? state.data.attachments : res.attachments);
        setAnonymizedFiles(
          state ? state.data.anonymizedFiles : res.anonymizedFiles
        );
        setInternalLabelFile(
          state ? state.data.internalLabelFile : res.internalLabelFile
        );

        setGlobalActReferences(
          state ? state.data.globalActReferences : res.globalActReferences
        );
        setUser(
          state
            ? {
                userGuid: state.data.userGuid,
                lockUserFullName: state.data.lockUserFullName,
              }
            : { userGuid: res.userGuid, lockUserFullName: res.lockUserFullName }
        );

        let disputedResult = (
          state ? state.data.disputedActs : res.disputedActs
        )
          .map((el) => {
            return el.regActName;
          })
          .join("\n");
        setDisputedActsName(disputedResult);

        let relevantResult = (
          state ? state.data.relevantLaws : res.relevantLaws
        )
          .map((el) => {
            return el.regActName;
          })
          .join("\n");
        setRelevantLawsName(relevantResult);
      }
    } else {
      setCourtPracticeData(new CourtPracticeModel());
      setGlobalActReferences([]);
      setPrimaryActs([]);
      setDisputedActs([]);
      setRelevantLaws([]);
      setUser({
        userGuid: UserService.getUserGuid(),
        lockUserFullName: UserService.getUsername(),
      });
    }
  };

  const ProcedureKind = async () => {
    var response = await procedureKindClient.GetAllActiveProcedureKinds(true);
    var handledResponse = HandlingResponse(response);
    var data =
      handledResponse !== "Error"
        ? handledResponse.map((item) => {
            return { value: item.id, label: item.name };
          })
        : [];
    setFkProcedureKindList(data);
  };

  const ProcedureOutcome = async () => {
    var response = await outcomeClient.GetAllActiveProcedureOutcomes(true);
    var handledResponse = HandlingResponse(response);
    var data =
      handledResponse !== "Error"
        ? handledResponse.map((item) => {
            return { value: item.id, label: item.name };
          })
        : [];
    setFkProcedureOutcomeList(data);
  };

  const CourtPracticeSubgroupClarification = async () => {
    var response = await client.CourtPracticeSubgroupClarification(true);
    var handledResponse = HandlingResponse(response);
    var data =
      handledResponse !== "Error"
        ? handledResponse.map((item) => {
            return {
              value: item.id,
              label: item.name,
              name: "fkClarificationCourtPracticeSubgroup",
            };
          })
        : [];
    setFkClarificationCourtPracticeSubgroupList(data);
  };

  const CourtPracticeSubgroupSentence = async () => {
    var response = await client.CourtPracticeSubgroupSentence(true);
    var handledResponse = HandlingResponse(response);
    var data =
      handledResponse !== "Error"
        ? handledResponse.map((item) => {
            return {
              value: item.id,
              label: item.name,
              name: "fkSentenceCourtPracticeSubgroup",
            };
          })
        : [];
    setFkSentenceCourtPracticeSubgroupList(data);
  };

  const CourtPracticeSubgroupLegalStance = async () => {
    var response = await client.CourtPracticeSubgroupLegalStance(true);
    var handledResponse = HandlingResponse(response);
    var data =
      handledResponse !== "Error"
        ? handledResponse.map((item) => {
            return {
              value: item.id,
              label: item.name,
              name: "fkLegalStanceCourtPracticeSubgroup",
            };
          })
        : [];
    setFkLegalStanceCourtPracticeSubgroupList(data);
  };

  const LegalMatter = async () => {
    var response = await legalMatterClient.GetAllActiveLegalMatters(true);
    var handledResponse = HandlingResponse(response);
    var data =
      handledResponse !== "Error"
        ? handledResponse.map((item) => {
            return { value: item.id, label: item.name, name: "fkLegalMatter" };
          })
        : [];
    setLegalMatterList(data);
  };

  const LegalSubmatter = async () => {
    var response = await legalSubmatterClient.GetLegalSubmattersByLegalMatter(
      courtPracticeData.fkLegalMatter,
      true
    );
    var handledResponse = HandlingResponse(response);
    var data =
      handledResponse !== "Error"
        ? handledResponse.map((item) => {
            return { value: item.id, label: item.name };
          })
        : [];
    setLegalSubmatterList(data);
  };

  const LegalArea = async () => {
    var response = await legalAreaClient.GetAllActiveLegalArea(true);
    var handledResponse = HandlingResponse(response);
    var data =
      handledResponse !== "Error"
        ? handledResponse.map((item) => {
            return { value: item.id, label: item.name, name: "fkLegalArea" };
          })
        : [];
    setLegalAreaList(data);
  };

  const ActKind = async () => {
    var response = await actKindClient.GetAllActiveActKinds(true);
    var handledResponse = HandlingResponse(response);
    var data =
      handledResponse !== "Error"
        ? handledResponse.map((item) => {
            return { value: item.id, label: item.name };
          })
        : [];
    setActKindList(data);
  };

  const Register = async () => {
    var response = await registerClient.GetAllActiveRegisters(true);
    var handledResponse = HandlingResponse(response);
    var data =
      handledResponse !== "Error"
        ? handledResponse.map((item) => {
            return { value: item.id, label: item.value };
          })
        : [];
    setRegisterList(data);
  };

  const createOrUpdateCourtPractice = async (event) => {
    event.preventDefault();
    if (internalLabelFile) {
      courtePracticeSchema.internalLabelFile = Joi.optional();
    }

    if (!primaryPublicationExist) {
      courtePracticeSchema.primaryRequest = Joi.required();
    } else if (
      courtPracticePublications.length != 0 &&
      primaryPublicationExist
    ) {
      courtePracticeSchema.primaryRequest = Joi.optional();
    }

    const errorsResult = validateForm(
      { ...dataForSubmit, primaryPublicationExist: primaryPublicationExist },
      courtePracticeSchema,
      t
    );
    setErrors(errorsResult);

    const disputedActsValidation = checkCharactersValidation(disputedActsName);
    const relevantLawsValidation = checkCharactersValidation(relevantLawsName);

    setErrors((prevErrors) => ({
      ...prevErrors,
      disputedActsValidation: disputedActsValidation,
      relevantLawsValidation: relevantLawsValidation,
    }));

    if (
      !errorsResult &&
      disputedActsValidation === "" &&
      relevantLawsValidation === ""
    ) {
      var formData = new FormData();

      dataForSubmit.primaryActs.forEach((x, i) => {
        x.sort = i;
      });

      serializeObjectIntoFormData(formData, dataForSubmit);

      for (let i = 0; i < attachmentFiles.length; i++) {
        if (attachmentFiles[i].guid)
          formData.append(
            "attachments[" + i + "].guid",
            attachmentFiles[i].guid
          );
        else formData.append("attachments[" + i + "].file", attachmentFiles[i]);

        formData.append("attachments[" + i + "].name", attachmentFiles[i].name);
      }
      for (let i = 0; i < anonymizedFiles.length; i++) {
        if (anonymizedFiles[i].guid)
          formData.append(
            "anonymizedFiles[" + i + "].guid",
            anonymizedFiles[i].guid
          );
        else
          formData.append(
            "anonymizedFiles[" + i + "].file",
            anonymizedFiles[i]
          );

        formData.append(
          "anonymizedFiles[" + i + "].name",
          anonymizedFiles[i].name
        );
      }

      if (internalLabelFile?.guid) {
        formData.append("internalLabelFile.guid", internalLabelFile?.guid);
        formData.append("internalLabelFile.name", internalLabelFile?.name);
      } else formData.append("internalLabelFile.file", internalLabelFile);

      if (caseIdValue) {
        var response = await client.UpdateCourtPractice(formData);
        var handledResponse = HandlingResponse(response);
      } else {
        response = await client.CreateCourtPractice(formData);
        handledResponse = HandlingResponse(response);
      }

      if (handledResponse?.code == 408) {
        var result = serverValidation(handledResponse?.data, t);
        setErrors(result);
      }
      if (handledResponse === "Success") navigate("/sp");
    }
  };

  const handlePropertyChange = (event, date) => {
    const obj = date
      ? { name: date, value: event }
      : { name: event.name, value: event.value };
    const result = handleSave(
      obj,
      dataForSubmit,
      errors,
      courtePracticeSchema,
      t
    );
    setCourtPracticeData(result.data);
    setErrors(result.errors);
  };

  const handleCheckboxProperty = (event, checkboxName, selectListName) => {
    const obj = { name: "selectOneAtLeast", value: event.target.checked };
    const result = handleSave(
      obj,
      dataForSubmit,
      errors,
      courtePracticeSchema,
      t
    );

    setCourtPracticeData((prevData) => {
      const newData = { ...prevData };
      if (!event.target.checked) {
        if (selectListName) newData[selectListName] = undefined;
        newData[checkboxName] = event.target.checked;
      } else {
        newData[checkboxName] = event.target.checked;
      }
      return newData;
    });

    setErrors(result.errors);
  };

  const handleUploadInternalLabelFileClick = () => {
    internalLabelFileInputRef.current?.click();
  };

  const removeFile = () => {
    setInternalLabelFile(null);
    //setInternalLabelName(null);
    internalLabelFileInputRef.current.value = null;
  };

  const downloadFile = async (file) => {
    var response = await client.DownloadFile(file.guid, file.name);
    HandlingResponse(response);
  };

  const downloadFilesFromRepo = async () => {
    var response = await client.DownloadFilesFromRepo(
      caseIdValue,
      internalLabelFile.name
    );
    HandlingResponse(response);
  };

  const onCancel = async () => {
    if (caseIdValue) {
      var response = await client.CancelUpdateCourtPractice(caseIdValue);
      HandlingResponse(response);
    }
    setErrors({});
    navigate("/sp");
  };

  const onDeleteClick = async () => {
    var response = await client.DeleteCourtPractice(caseIdValue);
    HandlingResponse(response);
    navigate("/sp");
  };

  const editHtml = async () => {
    const allOptionsVisible = true; // da li se vide menu, tooolbar, html prikaz
    const pathToNavigate = window.location.pathname;
    const id = courtPracticeData.id;
    const fileName = internalLabelFile.name;
    const isMainFile = true;
    if (useTextEditorLite)
      window.open(
        window.location.origin +
          "/textEditorLite?id=" +
          id +
          "&fileName=" +
          fileName +
          "&appPath=" +
          pathToNavigate +
          "&optionsVisible= " +
          allOptionsVisible +
          "&isMainFile=" +
          isMainFile,
        "_blank"
      );
    else
      window.open(
        window.location.origin +
          "/textEditor?id=" +
          id +
          "&fileName=" +
          fileName +
          "&appPath=" +
          pathToNavigate +
          "&optionsVisible= " +
          allOptionsVisible +
          "&isMainFile=" +
          isMainFile,
        "_blank"
      );
  };

  const handleCourtPracticePublicationsPrimaryChange = (isPrimary) => {
    const obj = { name: "primaryRequest", value: isPrimary };
    const result = handleSave(
      obj,
      primaryPublicationExist,
      errors,
      courtePracticeSchema,
      t
    );
    setPrimaryPublicationExist(isPrimary);
    setErrors(result.errors);
  };

  const checkCharactersValidation = (sentence) => {
    const parentheses = validateParentheses(sentence);
    const questionMarks = validateQuotationMarks(sentence);

    if (!parentheses && !questionMarks) {
      var message = t("parenthesesAndQuestionMarksMessage");
    } else if (!parentheses) {
      message = t("parenthesesMessage");
    } else if (!questionMarks) {
      message = t("questionMarksMessage");
    } else {
      message = "";
    }

    return message;
  };

  return (
    <>
      <form onSubmit={createOrUpdateCourtPractice}>
        <div className="form-group">
          <div className="clearfix">
            {courtPracticeData.locked &&
              user.userGuid !== UserService.getUserGuid() && (
                <label style={{ color: "red", marginBottom: "15px" }}>
                  {`Корисник ${user.lockUserFullName} уређује овај документ`}
                </label>
              )}
          </div>
          <label>
            ХТМЛ фајл<span className="required-asterisk">*</span>
          </label>
          <br />
          {internalLabelFile ? (
            <>
              <label>Назив фајла: </label> {internalLabelFile.name}
              {internalLabelFile.guid ? (
                <DownloadButton
                  title="Преузми фајл"
                  type="button"
                  onClick={() => downloadFile(internalLabelFile)}
                >
                  <FontAwesomeIcon icon={solid("download")} />
                </DownloadButton>
              ) : (
                <></>
              )}
              <ButtonWithComfirmation
                onClick={removeFile}
                handleYesText={"Обриши фајл"}
                contentText={
                  "Уколико сачувате измене, фајл ће бити трајно обрисан"
                }
                className="deleteButton"
              >
                <FontAwesomeIcon icon={solid("times")} />
              </ButtonWithComfirmation>
              {internalLabelFile.guid &&
              ((state?.data.locked &&
                user.userGuid === UserService.getUserGuid()) ||
                !state?.data.locked) ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={editHtml}
                    style={{ marginLeft: "10px" }}
                  >
                    Измени ХТМЛ
                  </button>
                  <br />
                  <input
                    id="textEditorLite"
                    type="checkbox"
                    value={useTextEditorLite}
                    checked={useTextEditorLite}
                    onChange={(event) =>
                      setUseTextEditorLite(event.target.checked)
                    }
                  />
                  <label htmlFor="textEditorLite">
                    Желим да користим упрошћену верзију Уређивача текста
                  </label>
                  <span className="info-about-cancel-span">
                    <FontAwesomeIcon icon={solid("circle-info")} /> Погодна за
                    фајлове чији је рад у основној верзији спор или немогућ
                  </span>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <div>
                <NewFileBtn
                  onClick={handleUploadInternalLabelFileClick}
                  type="button"
                >
                  <FontAwesomeIcon icon={solid("plus")} />
                  Одабери ХТМЛ фајл
                </NewFileBtn>
              </div>
              <input
                type="file"
                ref={internalLabelFileInputRef}
                onChange={(event) => {
                  const file = event.target.files[0];
                  if (file.type !== "text/html") {
                    setPdfError("Дозвољени су само ХТМЛ фајлови!");
                    setInternalLabelFile(null);
                  } else {
                    setPdfError("");
                    setInternalLabelFile(file);
                  }
                }}
                hidden
                accept=".html, .htm"
                name="internalLabelFile"
              />
            </>
          )}
          {pdfError && <div className="text-danger">{pdfError}</div>}
          {errors?.internalLabelFile && (
            <div className="text-danger">{errors.internalLabelFile}</div>
          )}
        </div>

        <CourtCase
          courtPracticeCourts={courtPracticeCourts}
          setCourtPracticeCourts={setCourtPracticeCourts}
          errors={errors}
          setErrors={setErrors}
        />
        <div className="form-group">
          <label>
            Правна материја<span className="required-asterisk">*</span>
          </label>
          <Select
            options={legalMatterList}
            onChange={(event) => handlePropertyChange(event)}
            value={legalMatterList?.filter(
              (x) => x.value === courtPracticeData.fkLegalMatter
            )}
          />
          {errors?.fkLegalMatter && (
            <div className="text-danger">{errors.fkLegalMatter}</div>
          )}
        </div>
        <div className="form-group">
          <label>Правна подматерија</label>
          <Select
            options={legalSubmatterList}
            onChange={(event) => {
              setCourtPracticeData({
                ...courtPracticeData,
                fkLegalSubmatter: event ? event.value : undefined,
              });
            }}
            value={legalSubmatterList?.filter(
              (x) => x.value === courtPracticeData.fkLegalSubmatter
            )}
            isClearable
          />
        </div>
        <div className="form-group">
          <label>
            Правна област<span className="required-asterisk">*</span>
          </label>
          <Select
            options={legalAreaList}
            onChange={(event) => handlePropertyChange(event)}
            value={legalAreaList?.filter(
              (x) => x.value === courtPracticeData.fkLegalArea
            )}
          />
          {errors?.fkLegalArea && (
            <div className="text-danger">{errors.fkLegalArea}</div>
          )}
        </div>
        <div className="form-group">
          <label>Врста акта</label>
          <Select
            options={actKindList}
            onChange={(event) => {
              setCourtPracticeData({
                ...courtPracticeData,
                fkActKind: event ? event.value : undefined,
              });
            }}
            value={actKindList?.filter(
              (x) => x.value === courtPracticeData.fkActKind
            )}
            isClearable
          />
        </div>
        <div className="form-group">
          <div className="form-group">
            <input
              id="typeProcedureCB"
              name="hasProcedureKindDuration"
              type="checkbox"
              value={courtPracticeData.hasProcedureKindDuration || ""}
              checked={courtPracticeData.hasProcedureKindDuration || ""}
              onChange={(event) =>
                setCourtPracticeData({
                  ...courtPracticeData,
                  hasProcedureKindDuration: event.target.checked,
                })
              }
            />
            <label htmlFor="typeProcedureCB">Важење врсте поступка</label>
          </div>
        </div>
        <div className="form-group">
          <label>Врста поступка</label>
          <Select
            options={fkProcedureKindList}
            onChange={(event) => {
              setCourtPracticeData({
                ...courtPracticeData,
                fkProcedureKind: event ? event.value : undefined,
              });
            }}
            value={fkProcedureKindList?.filter(
              (x) => x.value === courtPracticeData.fkProcedureKind
            )}
            isClearable
          />
        </div>
        <div className="form-group">
          <label>Исход поступка</label>
          <Select
            options={fkProcedureOutcomeList}
            onChange={(event) => {
              setCourtPracticeData({
                ...courtPracticeData,
                fkProcedureOutcome: event ? event.value : undefined,
              });
            }}
            value={fkProcedureOutcomeList?.filter(
              (x) => x.value === courtPracticeData.fkProcedureOutcome
            )}
            isClearable
          />
        </div>
        <div className="form-group">
          <label>Ознака и број акта</label>
          <textarea
            name="actLabelAndNumber"
            className="form-control"
            value={courtPracticeData.actLabelAndNumber}
            onChange={(event) => handlePropertyChange(event.target)}
          ></textarea>
        </div>
        <div className="form-group">
          <label>Уписник</label>
          <Select
            options={registerList}
            onChange={(event) => {
              setCourtPracticeData({
                ...courtPracticeData,
                fkRegister: event ? event.value : undefined,
              });
            }}
            value={registerList?.filter(
              (x) => x.value === courtPracticeData.fkRegister
            )}
            isClearable
          />
        </div>
        <div className="form-group">
          <label>Датум доношења акта</label>
          <CustomDatePicker
            selected={courtPracticeData.actEnactmentDate}
            onChange={(date) =>
              setCourtPracticeData({
                ...courtPracticeData,
                actEnactmentDate: date,
              })
            }
          />
        </div>
        <div className="form-group">
          <label>Година доношења акта</label>
          <DatePicker
            className="form-control"
            name="actEnactmentYear"
            selected={
              courtPracticeData.actEnactmentYear
                ? new Date(courtPracticeData.actEnactmentYear.toString())
                : null
            }
            onChange={(date) => {
              //prilikom slanja godine na back treba da bude year.getFullYear()
              setCourtPracticeData({
                ...courtPracticeData,
                actEnactmentYear: date?.getFullYear(),
              });
            }}
            showYearPicker
            dateFormat="yyyy"
            isClearable
          />
        </div>
        <div className="form-group">
          <label>
            Ближе одређење акта<span className="required-asterisk">*</span>
          </label>
          <textarea
            className="form-control"
            placeholder="Унесите ближе одређење"
            value={courtPracticeData.actCloserDesignation}
            name="actCloserDesignation"
            onChange={(event) => handlePropertyChange(event.target)}
          ></textarea>
          {errors?.actCloserDesignation && (
            <div className="text-danger">{errors.actCloserDesignation}</div>
          )}
        </div>
        <div className="form-group">
          <RelatedActs
            primaryActs={primaryActs}
            setPrimaryActs={setPrimaryActs}
            disputedActsName={disputedActsName}
            setDisputedActsName={setDisputedActsName}
            relevantLawsName={relevantLawsName}
            setRelevantLawsName={setRelevantLawsName}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
        <hr />
        <div className="form-group">
          <input
            id="clarificationCB"
            type="checkbox"
            value={courtPracticeData.isClarification || ""}
            checked={courtPracticeData.isClarification || ""}
            name="isClarification"
            onChange={(event) =>
              handleCheckboxProperty(
                event,
                "isClarification",
                "fkClarificationCourtPracticeSubgroup"
              )
            }
          />
          <label htmlFor="clarificationCB">Образложење</label>
        </div>
        <div className="form-group">
          <label>Подврста образложења</label>
          <Select
            options={fkClarificationCourtPracticeSubgroupList}
            isDisabled={courtPracticeData.isClarification == false}
            onChange={(event) => handlePropertyChange(event)}
            value={fkClarificationCourtPracticeSubgroupList?.filter(
              (x) =>
                x.value ===
                courtPracticeData.fkClarificationCourtPracticeSubgroup
            )}
          />
          {errors?.fkClarificationCourtPracticeSubgroup && (
            <div className="text-danger">
              {errors.fkClarificationCourtPracticeSubgroup}
            </div>
          )}
        </div>
        <div className="form-group">
          <input
            id="sentenceCB"
            type="checkbox"
            value={courtPracticeData.isSentence || ""}
            checked={courtPracticeData.isSentence || ""}
            name="isSentence"
            onChange={(event) =>
              handleCheckboxProperty(
                event,
                "isSentence",
                "fkSentenceCourtPracticeSubgroup"
              )
            }
          />
          <label htmlFor="sentenceCB">Сентенца</label>
        </div>
        <div className="form-group">
          <label>Подврста сентенце</label>
          <Select
            options={fkSentenceCourtPracticeSubgroupList}
            isDisabled={courtPracticeData.isSentence == false}
            onChange={(event) => handlePropertyChange(event)}
            value={fkSentenceCourtPracticeSubgroupList?.filter(
              (x) =>
                x.value === courtPracticeData.fkSentenceCourtPracticeSubgroup
            )}
          />
          {errors?.fkSentenceCourtPracticeSubgroup && (
            <div className="text-danger">
              {errors.fkSentenceCourtPracticeSubgroup}
            </div>
          )}
        </div>
        <div className="form-group">
          <input
            id="legalSentenceCB"
            type="checkbox"
            value={courtPracticeData.isLegalStance || ""}
            checked={courtPracticeData.isLegalStance || ""}
            name="isLegalStance"
            onChange={(event) =>
              handleCheckboxProperty(
                event,
                "isLegalStance",
                "fkLegalStanceCourtPracticeSubgroup"
              )
            }
          />
          <label htmlFor="legalSentenceCB">Правни став</label>
        </div>
        <div className="form-group">
          <label>Подврста правног става</label>
          <Select
            options={fkLegalStanceCourtPracticeSubgroupList}
            isDisabled={courtPracticeData.isLegalStance == false}
            onChange={(event) => handlePropertyChange(event)}
            value={fkLegalStanceCourtPracticeSubgroupList?.filter(
              (x) =>
                x.value === courtPracticeData.fkLegalStanceCourtPracticeSubgroup
            )}
          />
          {errors?.fkLegalStanceCourtPracticeSubgroup && (
            <div className="text-danger">
              {errors.fkLegalStanceCourtPracticeSubgroup}
            </div>
          )}
        </div>
        <div className="form-group">
          <input
            id="isDocumentConstruction"
            type="checkbox"
            value={courtPracticeData.isDocumentConstruction || ""}
            checked={courtPracticeData.isDocumentConstruction || ""}
            name="isDocumentConstruction"
            onChange={(event) =>
              handleCheckboxProperty(event, "isDocumentConstruction")
            }
          />
          <label htmlFor="isDocumentConstruction">Остала грађа</label>
        </div>
        {errors?.selectOneAtLeast && (
          <div className="text-danger">
            Морате чекирати барем једну категорију
          </div>
        )}
        <hr />
        <div className="form-group">
          <label>Датум усвајања правног схватања</label>
          <CustomDatePicker
            selected={courtPracticeData.legalUnderstandingAdoptionDate}
            onChange={(date) =>
              setCourtPracticeData({
                ...courtPracticeData,
                legalUnderstandingAdoptionDate: date,
              })
            }
          />
        </div>
        <div className="form-group">
          <input
            id="newestCB"
            type="checkbox"
            value={courtPracticeData.showAsNewest || ""}
            checked={courtPracticeData.showAsNewest || ""}
            name="showAsNewest"
            onChange={(event) =>
              setCourtPracticeData({
                ...courtPracticeData,
                showAsNewest: event.target.checked,
              })
            }
          />
          <label htmlFor="newestCB">Прикажи као најновије</label>
        </div>
        <div className="form-group">
          <input
            id="approvedCB"
            type="checkbox"
            value={courtPracticeData.isApproved || ""}
            checked={courtPracticeData.isApproved || ""}
            name="isApproved"
            onChange={(event) =>
              setCourtPracticeData({
                ...courtPracticeData,
                isApproved: event.target.checked,
              })
            }
          />
          <label htmlFor="approvedCB">Објави на порталу</label>
        </div>
        <div className="form-group">
          <label>Уредничка напомена</label>
          <textarea
            className="form-control"
            value={courtPracticeData.editorialNote}
            name="editorialNote"
            onChange={(event) =>
              setCourtPracticeData({
                ...courtPracticeData,
                editorialNote: event.target.value,
              })
            }
          ></textarea>
        </div>
        <div className="form-group row">
          <div className="col-6">
            <label>Анонимизиран оригинал судске одлуке</label>
            <MultipleFiles
              fileList={anonymizedFiles}
              setFileList={setAnonymizedFiles}
              accept={".pdf"}
              name={"anonymizedFiles"}
              handleDownload={downloadFile}
            />
          </div>
          <div className="col-6">
            <label>Прилози</label>
            <MultipleFiles
              fileList={attachmentFiles}
              setFileList={setAttachmentFiles}
              accept={".pdf"}
              name={"attachment"}
              handleDownload={downloadFile}
            />
          </div>
        </div>
        <br />
        <GlobalActReferences
          globalActReferencesData={globalActReferences}
          setGlobalActReferencesData={setGlobalActReferences}
        />
        <br />
        <div>
          <CourtPracticePublications
            courtPracticePublications={courtPracticePublications}
            setCourtPracticePublications={setCourtPracticePublications}
            primaryPublicationExist={primaryPublicationExist}
            setPrimaryPublicationExist={setPrimaryPublicationExist}
            handleCourtPracticePublicationsPrimaryChange={
              handleCourtPracticePublicationsPrimaryChange
            }
          />
          {errors?.primaryRequest && (
            <div className="text-danger">
              Мора да постоји једна примарна објава
            </div>
          )}
        </div>
        <div>
          {caseIdValue && (
            <>
              <div>
                <button
                  className="btn btn-primary pull-left"
                  type="button"
                  onClick={downloadFilesFromRepo}
                >
                  Преузми фајлове са репозиторијума
                </button>
              </div>
              <br />
            </>
          )}
          {caseIdValue &&
            !state?.data.isApproved &&
            ((state?.data.locked &&
              user.userGuid === UserService.getUserGuid()) ||
              !state?.data.locked) && (
              <ButtonWithComfirmation
                className="btn btn-danger"
                onClick={() => onDeleteClick()}
                contentText={"Предмет ће бити трајно обрисан."}
                handleYesText={"Обриши"}
              >
                Обриши предмет
              </ButtonWithComfirmation>
            )}

          {user.userGuid === UserService.getUserGuid() && (
            <>
              <button
                type="button"
                className="btn btn-primary  pull-right"
                onClick={onCancel}
              >
                Одустани
              </button>
              <button
                className="btn btn-primary"
                type="submit"
              >
                Сачувај
              </button>

              <div>
                {caseIdValue ? (
                  <span className="info-about-cancel-span">
                    <FontAwesomeIcon icon={solid("circle-info")} /> Уколико не
                    кликнете на дугме "Одустани" документ ће остати закључан и
                    други корисници неће моћи да врше измену над њим
                  </span>
                ) : (
                  <span className="info-about-cancel-span">
                    <FontAwesomeIcon icon={solid("circle-info")} /> Предмет неће
                    бити сачуван док не кликнете на дугме "Сачувај"
                  </span>
                )}
              </div>
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default CreateAndUpdateCase;
